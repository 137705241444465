import React from 'react';

class Services extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">Our Service</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">We redefine customer engagement via video call center solution. <br/> Allowing customer to reach representative agent with one click</p>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-cloud text-custom"></i>
                            <h4 className="padding-t-15">Clientless Solution</h4>
                            <p className="padding-t-15 text-muted">Zero software needed to be installed</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-magic-wand text-custom"></i>
                            <h4 className="padding-t-15">Lossless Compression Algorithm</h4>
                            <p className="padding-t-15 text-muted">End-to-end compression algorithm to deliver a quality video/audio solution</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-lock text-custom"></i>
                            <h4 className="padding-t-15">Data Encryption</h4>
                            <p className="padding-t-15 text-muted">An encrypted connection established on every video/audio communication</p>
                        </div>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-users text-custom"></i>
                            <h4 className="padding-t-15">Know your clients</h4>
                            <p className="padding-t-15 text-muted">Generate a report on your client's details</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-news-paper text-custom"></i>
                            <h4 className="padding-t-15">Reports</h4>
                            <p className="padding-t-15 text-muted">Receive a monthly report on your performance and the client's satisfaction</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-link text-custom"></i>
                            <h4 className="padding-t-15">Integratable Solution</h4>
                            <p className="padding-t-15 text-muted">Integratable solution to any client</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}
export default Services;