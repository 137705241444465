import React from "react";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import Clients from "./components/Clients";

import Features from "./components/Features";
// import Descriptions from "./components/Descriptions";
// import Pricing from "./components/Pricing";
// import Team from "./components/Team";
// import Process from "./components/Process";
// import Testi from "./components/Testi";
import Started from "./components/Started";
// import Blog from "./components/Blog";
import Contact from "./components/Contact";
import SocialMedia from "./components/SocialMedia";
// import Footer from "./components/Footer";
import FooterLinks from "./components/FooterLinks";
// import Switcher from "./components/Switcher";
import Particles from "react-particles-js";
// import { Link } from "react-router-dom";
import Aux from "./hoc/Aux_";
import TextLoop from "react-text-loop";

class HomeNine extends React.Component {
  render() {
    var mystyle = { position: "absolute" };
    return (
      <Aux>
        {/* Navbar Component*/}
        <Navbar />
        <section className="section bg-home height-100vh" id="home">
          <div className="bg-overlay" />
          <div className="">
            <div className="">
              <div className="container slidero">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">
                      Modern Problems Requires Modern{" "}
                      <TextLoop interval={1000}>
                        <span>Real-Time</span>
                        <span>Engaging</span>
                        {/* <BodyText>Thrilling</BodyText> */}
                        <span>Interactive</span>
                        <span>Robust</span>
                      </TextLoop>{" "}
                      Solutions
                    </h1>
                    <p className="padding-t-15 home-desc">
                    Antillia Talk provides a secured real-time communication platform, that redefine the customer engagement with its entity. Allowing customer to video call a representative agent with one click.
                    </p>
                    {/* <p className="play-shadow margin-t-30 margin-l-r-auto"> <Link to="http://vimeo.com/99025203"  className="play-btn video-play-icon"><i className="mdi mdi-play text-center"></i></Link></p>   */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Particles
            style={mystyle}
            params={{
              particles: {
                number: {
                  value: 200
                },
                size: {
                  value: 4
                }
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "repulse"
                  }
                }
              }
            }}
          />
        </section>

        {/* Services Component*/}
        <Services />

        {/* Features Component*/}
        {/* <Features /> */}

        {/* Descriptions Component*/}
        {/* <Descriptions /> */}

        {/* Pricing Component*/}
        {/* <Pricing /> */}

        {/* Team Component*/}
        {/* <Team /> */}

        {/* Process Component*/}
        {/* <Process /> */}

        {/* Testi Component*/}
        {/* <Testi /> */}

        {/* Started Component*/}
        {/* <Started /> */}

        {/* Blog Component*/}
        {/* <Blog /> */}

        {/* Clients Component */}
        {/* <Clients /> */}

        {/* Contact Component*/}
        {/* <Contact /> */}

        {/* SocialMedia Component*/}
        <SocialMedia />

        {/* Footer Component*/}
        {/* <Footer /> */}

        {/* FooterLinks Component*/}
        <FooterLinks />

        {/* Switcher Component*/}
        {/* <Switcher />  */}
      </Aux>
    );
  }
}

export default HomeNine;
