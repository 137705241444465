import React from "react";
import NetlifyForm from "react-netlify-form";

class Contact extends React.Component {
  render() {
    return (
      <section className="section" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">Talk</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
              We would love to know how can we enhance your customer's experience.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-form mt-4 pt-4">
                <div id="message" />
                <NetlifyForm name="contact">
                  {({ loading, error, success }) => (
                    <div>
                      {loading && <div>Loading...</div>}
                      {error && (
                        <div className="row">
                          <div className="alert alert-danger" role="alert">
                            Your information was not sent. Please try again
                            later or Send us an email   <a href="mailto:find@antillia.ai" className="alert-link">find@antillia.ai</a> .
                          </div>
                        </div>
                      )}
                      {success && (
                        <div className="row">
                          <div className="alert alert-success" role="alert">
                            Thank you for contacting us!
                          </div>
                        </div>
                      )}
                      {!loading && !success && (
                        <div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group mt-2">
                                <input
                                  name="name"
                                  id="name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Your name*"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group mt-2">
                                <input
                                  name="email"
                                  id="email"
                                  type="email"
                                  className="form-control"
                                  placeholder="Your email*"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group mt-2">
                                <input
                                  name="number"
                                  id="number"
                                  type="number"
                                  className="form-control"
                                  placeholder="Your Number*"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group mt-2">
                                <textarea
                                  name="message"
                                  id="comments"
                                  rows="4"
                                  className="form-control"
                                  placeholder="Your message..."
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 text-right">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-custom"
                                value="Send Message"
                              />
                              <div id="simple-msg" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </NetlifyForm>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;
