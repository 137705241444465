import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Clients extends React.Component {
  render() {
    return (
      <section className="section pt-5" id="clients">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">Our Clients</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
                We are very fortunate to have formed excellent partnerships with
                many of our clients
              </p>
            </div>
          </div>
          <div className="row text-center">
            <div />
            <div />
            <div />

            <OwlCarousel className="owl-theme" loop margin={10} nav lazyLoad center>
              <div className="col-lg-12">
                <div className="item" styles="width:250px">
                  <img
                    className="img-fluid partner"
                    src="images/clients/logo-1.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="item" styles="width:250px">
                  <img
                    className="img-fluid partner"
                    src="images/clients/logo-2.png"
                    alt=""
                  />
                </div>
              </div>

              {/* <div className="col-lg-12">
                <div className="item"styles="width:250px">
                  <img
                    className="img-fluid partner"
                    src="images/clients/logo-3.png"
                    alt=""
                  />
                </div>
              </div> */}
            </OwlCarousel>
          </div>
        </div>
      </section>
    );
  }
}

export default Clients;
